import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Mousewheel, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import Image from "next/image";

const MediaCoverage = () => {
    return (
        <div>
            <h1 className='text-center my-5 text-base md:text-2xl'>Media Coverage</h1>
            <Swiper cssMode={true}
                slidesPerView={3} spaceBetween={30} autoplay
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Pagination]}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    480: {
                        slidesPerView: 2,
                    },
                    640: {
                        slidesPerView: 3,
                    },
                }}
                className="mySwiper">

                <SwiperSlide>
                    <LiteYouTubeEmbed
                        id="qkw4IOJKRUY"
                        title="1"
                        adNetwork={true}
                        params=""
                        playlist={false}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <LiteYouTubeEmbed
                        id="5-4hUztW6J4"
                        title="2"
                        adNetwork={true}
                        params=""
                        playlist={false}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <LiteYouTubeEmbed
                        id="kuP7ANjyKms"
                        title="1"
                        adNetwork={true}
                        params=""
                        playlist={false}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <LiteYouTubeEmbed
                        id="BdwkD0R3aHc"
                        title="1"
                        adNetwork={true}
                        params=""
                        playlist={false}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <LiteYouTubeEmbed
                        id="rTPONfyr-s8"
                        title="1"
                        adNetwork={true}
                        params=""
                        playlist={false}
                    />
                </SwiperSlide>
            </Swiper>
            <div className='grid grid-cols-2 md:grid-cols-4 mt-2'>
                <Image onClick={() => window.open("https://aninews.in/", "_blank")} src="/images/newsAni.png" alt='newsani' width={150}
                    height={120} objectFit="contain" objectPosition="bottom bottom" className='cursor-pointer' />

                <Image onClick={() => window.open("https://news24online.com/", "_blank")} src="/images/news24.png" alt='news24' width={150}
                    height={120} objectFit="contain" objectPosition="bottom bottom" className='cursor-pointer' />

                <Image onClick={() => window.open("https://theprint.in/", "_blank")} src="/images/thePrint.png" alt='thePrint' width={150}
                    height={120} objectFit="contain" objectPosition="bottom bottom" className='cursor-pointer' />

                <Image onClick={() => window.open("https://newspatrolling.com/", "_blank")} src="/images/np.png" alt='np' width={150}
                    height={120} objectFit="contain" objectPosition="bottom bottom" className='cursor-pointer' />



            </div>
            <div className='grid grid-cols-2 md:grid-cols-4'>
                <Image onClick={() => window.open("https://m.dailyhunt.in/", "_blank")} src="/images/dailyHunt.png" alt='dailyHunt' width={150}
                    height={120} objectFit="contain" objectPosition="bottom bottom" className='cursor-pointer' />

                <Image onClick={() => window.open("https://businessbeats.in/", "_blank")} src="/images/businessBeats.png" alt='businessBeats' width={150}
                    height={120} objectFit="contain" objectPosition="bottom bottom" className='cursor-pointer' />

                <Image onClick={() => window.open("https://businessbeats.in/", "_blank")} src="/images/fringe.png" alt='fringe' width={150}
                    height={120} objectFit="contain" objectPosition="bottom bottom" className='cursor-pointer' />

                <Image onClick={() => window.open("https://www.bizjournals.com/", "_blank")} src="/images/journals.png" alt='journals' width={150}
                    height={120} objectFit="contain" objectPosition="bottom bottom" className='cursor-pointer' />


            </div>
        </div >
    )
}

export default MediaCoverage

